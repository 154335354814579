body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 1em;
}

button {
  margin-left: 1em;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1em;
  width: 300px;
  border-radius: 8px;
}

@keyframes fadeIn {
    from {opacity:0;}
    to {opacity:1;}
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: 2s;
}

h2 {
  color: #333;
}

p {
  color: #666;
}

button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
}

button:hover {
    opacity:0.8; 
}

form {
    background-color:white; 
    padding:20px; 
    border-radius:8px; 
    margin-bottom:10px; 
    box-shadow :0px,0px,10px,2px rgba(0,0,0,0.1);
}

input, textarea, select {
    width :100%; 
    padding :12px; 
    border :1px solid #ccc; 
    border-radius :4px; 
    box-sizing :border-box; 
    margin-top :6px; 
    margin-bottom :16px; 
}

.task-list-item {
    border-radius :8px; 
    box-shadow :0px,0px,10px,2px rgba(0,0,0,0.1);
    margin-bottom :10px; 
    padding :20px; 
    background-color:white; 
}

.nav-username {
   font-size :24px; 
   font-weight:bold; 
   color: #f4f4f4;
   padding-left: 20px
}

